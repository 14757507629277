/**
 * @param {Number} ms
 * @param args
 * @returns {*}
 */
export function timeoutPromise(ms, ...args) {
    let cancel = null
    const promise = new Promise((resolve, reject) => {
        const timeoutID = setTimeout(resolve, ms, ...args)
        cancel = (message = 'clearTimeout') => {
            clearTimeout(timeoutID)
            reject(message)
        }
    })
    promise.cancel = cancel
    return promise
}
