export default {
  async fetch({commit}) {
    try {
      const profile = await this.$axios.get('profile', {
        params: {
          '$permissions': 1
        }
      })
      commit('SET_DATA', profile)
      this.$fire.analytics.setUserId(profile.employee_id)
      return true
    } catch (e) {
      return false
    }
  },
  async subscribeNofitication({}) {

  },
  async unsubscribeNofitication({}) {

  },
  async fetchProfileConfig({}) {

  }
}
