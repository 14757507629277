import { filterToQuery } from '@/utils/smarteh/materials/filter'
import { paginationToQuery } from '@/utils/smarteh/pagination'

let fetchMaterials = null,
  fetchProperties = null

export default {

  async fetch ({ commit, state }) {
    if (fetchMaterials) fetchMaterials.cancel()
    commit('SET_ERROR')
    commit('SET_LOADING', true)
    const params = {
      ...filterToQuery(state.filter),
      ...paginationToQuery(state),
    }
    fetchMaterials = this.$axios.getWithCancel(`materials`, { params })
    fetchMaterials.then(({ data, meta }) => {
      fetchMaterials = null
      commit('SET_MATERIALS', data)
      commit('SET_META', meta)
      commit('SET_LOADING', false)
    }).catch((e) => {
      if (this.$axios.isCancel(e)) return
      fetchMaterials = null
      commit('SET_ERROR', e)
      commit('SET_LOADING', false)
    })
    return fetchMaterials
  },
  async fetchCategories ({ commit, state }) {
    if (state.filter_loading.categories) return
    try {
      commit('SET_FILTER_LOADING', { categories: true })
      commit('SET_FILTER_ERROR', { categories: null })
      const categories = await this.$axios.get(`materials/filter/categories`)
      commit('SET_FILTER_OPTIONS', { categories })
    } catch (e) {
      commit('SET_FILTER_ERROR', { categories: e })
    } finally {
      commit('SET_FILTER_LOADING', { categories: false })
    }
  },
  async fetchProperties ({ commit, state }, category_id) {
    if (fetchProperties) fetchProperties.cancel()
    if (!category_id || !category_id.length) {
      commit('SET_FILTER_OPTIONS', { properties: null })
      return
    }
    commit('SET_FILTER_LOADING', { properties: true })
    commit('SET_FILTER_ERROR', { properties: null })
    fetchProperties = this.$axios.getWithCancel(`materials/filter/properties`, {
      params: filterToQuery({ category_id }),
    })
    fetchProperties.then((data) => {
      fetchProperties = null
      commit('SET_FILTER_OPTIONS', { properties: data })
      commit('SET_FILTER_LOADING', { properties: false })
    }).catch((e) => {
      if (this.$axios.isCancel(e)) return
      fetchProperties = null
      commit('SET_FILTER_ERROR', { properties: e })
      commit('SET_FILTER_LOADING', { properties: false })
    })
    return fetchProperties
  },
}
