
import { mapMutations, mapState, mapActions } from 'vuex'
import { fetchFactory } from '@/mixins/fetch'

export default {
  props: ['show'],
  mixins: [
    fetchFactory('subscriptions', 'notifications/subscriptions'),
  ],
  data: () => ({
    tab: null,
    subscriptionsSaving: {},
    pendingNotificationPermission: false,
  }),
  computed: {
    ...mapState({
      profile: state => state.profile.data
    }),
    theme_mode: {
      get () {
        return this.$store.state.profile.theme.mode
      },
      set (value) {
        this.$store.commit('profile/SET_THEME_MODE', value)
      }
    },
    theme_config: {
      get () {
        const { day_start, day_end } = this.$store.state.profile.theme
        return { day_start, day_end }
      },
      set ({ day_start, day_end }) {
        this.$fire.analytics.setUserProperties({
          light_theme_period: `${day_start}-${day_end}` || value
        })
        this.$store.commit('profile/SET_THEME_CONFIG', { day_start, day_end })
      }
    },
    theme_config_slider: {
      get () {
        return [
          +this.$dayjs(this.theme_config.day_start, 'HH:mm').format('H'),
          +this.$dayjs(this.theme_config.day_end, 'HH:mm').format('H')
        ]
      },
      set (value) {
        this.theme_config = {
          day_start: `${value[0].toString().padStart(2, '0')}:00`,
          day_end: `${value[1].toString().padStart(2, '0')}:00`
        }
      }
    },
    supportSystemDark () {
      return !!window.matchMedia
    },
    datetime_picker: {
      get () {
        return this.$store.state.profile.config.datetime_picker
      },
      set (v) {
        this.$fire.analytics.setUserProperties({
          datetime_picker: ['Авто', 'Календарь й круглий годинник', 'Календарь й випадаючий список'][v] || v
        })
        this.$store.commit('profile/SET_CONFIG', {
          datetime_picker: v
        })
      }
    },
    name_format: {
      get () {
        return this.$store.state.profile.config.name_format
      },
      set (v) {
        this.$fire.analytics.setUserProperties({
          name_format: ({ 'name_surname': 'Ім\'я Прізвище', 'surname_name': 'Прізвище Ім\'я' })[v] || v
        })
        this.$store.commit('profile/SET_CONFIG', {
          name_format: v
        })
      }
    },
    tasks_with_comments: {
      get () {
        return this.$store.state.profile.config.tasks_with_comments
      },
      set (v) {
        this.$fire.analytics.setUserProperties({
          tasks_with_comments: v
        })
        this.$store.commit('profile/SET_CONFIG', {
          tasks_with_comments: v
        })
      }
    },
    tasks_with_workers: {
      get () {
        return this.$store.state.profile.config.tasks_with_workers
      },
      set (v) {
        this.$fire.analytics.setUserProperties({
          tasks_with_workers: v
        })
        this.$store.commit('profile/SET_CONFIG', {
          tasks_with_workers: v
        })
      }
    }
  },
  methods: {
    ...mapMutations({
      showProfile: 'app/SHOW_PROFILE'
    }),
    ...mapActions({
      fetchProfile: 'profile/fetch'
    }),
    async toggleNotification () {
      if (this.pendingNotificationPermission) return
      this.pendingNotificationPermission = true
      await this.$nextTick()
      try {
        if (this.$notification.enabled) {
          await this.$notification.disable()
        } else {
          await this.$notification.enable()
        }
      } finally {
        this.pendingNotificationPermission = false
      }
    },
    async saveSubscribe (subscription) {
      this.subscriptionsSaving[subscription.type]?.abort()
      await this.$nextTick()
      try {
        const controller = new AbortController
        this.$set(this.subscriptionsSaving, subscription.type, controller)
        await this.$axios.patch('notifications/subscriptions', {
          type: subscription.type,
          subscribe: subscription.subscribe
        }, {
          signal: controller.signal
        })
        this.$delete(this.subscriptionsSaving, subscription.type)
      } catch (e) {
        if (!this.$axios.isCancel(e)) {
          this.$delete(this.subscriptionsSaving, subscription.type)
        }
      }
    }
  },
  watch: {
    show () {
      if (!this.profile)
        this.fetchProfile()
      if (this.show) {
        this.$blockRouteLeave(this, () => {
          this.showProfile(false)
          return true
        })
        this.tab = 0
      } else {
        this.$unBlockRouteLeave(this)
      }
    },
    theme_mode (v) {
      this.$fire.analytics.setUserProperties({
        theme_mode: v
      })
    },
    tab (v) {
      if (v === 2) {
        this.fetchSubscriptions()
      }
    }
  },
}
