
import { fetchFactory } from '@/mixins/fetch'
import { groupBy } from '@/utils/array'

const NOTIFICATIONS_API = 'notifications'

export default {
  name: 'notifications-header',
  mixins: [
    fetchFactory('total', 'notifications/total', {
      params: {
        read: 0,
      },
      transformResponse (response) {
        this.total.timestamp = response.timestamp
        return response.data
      }
    }, { timestamp: null })
  ],
  inject: ['layout'],
  data: () => ({
    show: false,
    tab: 0,

    unreadNotifications: {
      data: [],
      meta: null,
      pending: false,
      pending_next: false,
      error: null,
      controller: null,
      hasChanges: false,
    },
    readNotifications: {
      data: [],
      meta: null,
      pending: false,
      pending_next: false,
      error: null,
      controller: null,
      hasChanges: false,
    },
    pending: [],
    totalInterval: null,
    pendingNotificationDevice: false,
  }),
  created () {
    this.fetchTotal().then((result) => {
      if (result?.data) {
        this.fetchUnreadNotifications()
      }
    })
  },
  mounted () {
    this.startAutoUpdateCount()
  },
  beforeDestroy () {
    this.stopAutoUpdateCount()
  },
  activated () {
    this.startAutoUpdateCount()
  },
  deactivated () {
    this.stopAutoUpdateCount()
  },
  computed: {
    groupReadNotifications () {
      return groupBy(
        this.readNotifications.data || [],
        (v) => this.$dayjs.unix(v.created_at).fromNow()
      )
    },
    groupUnreadNotifications () {
      return groupBy(
        this.unreadNotifications.data || [],
        (v) => this.$dayjs.unix(v.created_at).fromNow()
      )
    },
    nextCursor () {
      return {
        read: this.readNotifications.meta?.next_cursor,
        unread: this.unreadNotifications.meta?.next_cursor
      }
    }
  },
  methods: {
    getLink (notification) {
      if (notification.data?.task_id) {
        return `/tasks/${notification.data.task_id}`
      }
      if (notification.data?.report_uuid) {
        return `/reports/my`
      }
      if (notification.data?.warehouse_uuid) {
        return `/warehouses/${notification.data.warehouse_uuid}`
      }
    },
    maskAsRead (notification) {
      if (this.pending.includes(notification)) return
      this.pending.push(notification)
      return this.$axios.post(`/notifications/${notification.id}/read`).then(() => {
        this.unreadNotifications.data = this.unreadNotifications.data.filter(n => n !== notification)
        this.readNotifications.hasChanges = true
        this.$fire.analytics.logEvent('notification_read', {
          type: notification.type
        })
      }).finally(() => {
        this.pending = this.pending.filter(n => n !== notification)
      })
    },
    deleteNotification (notification) {
      if (this.pending.includes(notification)) return
      this.pending.push(notification)
      this.$axios.delete(`/notifications/${notification.id}`).then(() => {
        this.readNotifications.data = this.readNotifications.data.filter(n => n !== notification)
        this.$fire.analytics.logEvent('notification_delete', {
          type: notification.type
        })
      }).finally(() => {
        this.pending = this.pending.filter(n => n !== notification)
      })
    },
    startAutoUpdateCount() {
      let localCount
      this.totalInterval = setInterval(() => {
        localCount = this.total.data || 0
        this.fetchTotal().then((result) => {
          if (localCount !== result?.data) {
            this.fetchUnreadNotifications()
          }
        })
      }, 60000)
    },
    stopAutoUpdateCount() {
      this.totalInterval && clearInterval(this.totalInterval)
    },
    ...[['fetchUnreadNotifications', 'unreadNotifications'], ['fetchReadNotifications', 'readNotifications']].reduce((o, a, i) => {
      o[a[0]] = async function (next = false) {
        const constructor = this[a[1]]
        if (constructor.pending) return
        if (next && !constructor.meta?.next_cursor) return
        if (!next) {
          constructor.hasChanges = false
        }
        try {
          constructor.error = null
          constructor.pending = true
          constructor.pending_next = !!next

          const response = await this.$axios.get(NOTIFICATIONS_API, {
            params: {
              read: i,
              cursor: next && constructor.meta.next_cursor || ''
            }
          })
          constructor.meta = response.meta
          if (next) {
            constructor.data = constructor.data.concat(response.data)
          } else {
            constructor.data = response.data
          }
        } catch (e) {
          constructor.error = e
        } finally {
          constructor.pending_next = constructor.pending = false
        }
      }
      return o
    }, {}),
    onNewNotification (notification) {
      if (!notification.read_at && !this.unreadNotifications.data.some((n) => n.id === notification.id)) {
        this.unreadNotifications.data.unshift(notification)
        if (this.total.timestamp < notification.updated_at) {
          this.total.data = (this.total.data || 0) + 1
        }
      }
    },
    onReadNotification (notification) {
      if (notification) {
        this.unreadNotifications.data = this.unreadNotifications.data.filter(n => n.id !== notification.id)
      }
      if (this.total.data && this.total.timestamp < notification.read_at) {
        this.total.data--
      }
    },
    onDeleteNotification (notification) {
      const n = this.unreadNotifications.data?.find(n => n.id === notification.id)
      if (n) {
        this.unreadNotifications.data = this.unreadNotifications.data.filter(nn => n !== nn)
        if (this.total.data) this.total.data--
      }
      this.readNotifications.data = this.readNotifications.data.filter(n => n.id !== notification.id)
    },
    onClick (notification) {
      if (!notification.read_at) {
        this.maskAsRead(notification)
      }
    }
  },
  watch: {
    show (v) {
      if (v) {
        this.$fire.analytics.logEvent('open_notifications')
      }
    },
    'layout.employeeSocket': {
      handler (v, ov) {
        if (ov) {
          ov.off('employee:notification:created', this.onNewNotification)
          ov.off('employee:notification:read', this.onReadNotification)
          ov.off('employee:notification:deleted', this.onDeleteNotification)
        }
        if (v) {
          v.on('employee:notification:created', this.onNewNotification)
          v.on('employee:notification:read', this.onReadNotification)
          v.on('employee:notification:deleted', this.onDeleteNotification)
        }
      },
      immediate: true,
    },
    tab (v) {
      if (v) {
        if (!this.readNotifications.data?.length || this.readNotifications.hasChanges) {
          this.fetchReadNotifications()
        }
      }
    },
    'total.data'(v) {
      if (typeof v === 'number') {
        navigator?.setAppBadge && navigator?.setAppBadge(v)
      }
    }
  },
}
