export class SmartehError extends Error {
}

export class Warning extends SmartehError {
}

export class NotEnoughDataWarning extends Warning {
    constructor(message = 'Вказані не всі дані', ...args) {
        super(message, ...args);
    }
}
