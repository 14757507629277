export default {
  async login ({ commit }, { login, password }) {
    const { token, scopes } = await this.$axios.post('login', {
      login, password
    })
    commit('SET_TOKEN', { token, scopes })
    return true
  },
  async logout ({ commit, dispatch }) {
    this.$axios.post('/logout').catch(null)
    commit('RESET', null)
    dispatch('app/RESET', null, { root: true })
    return true
  }
}
