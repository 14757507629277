
  import { mapMutations } from 'vuex'
  import NotificationsHeader from "@/components/notifications/header";
  import { state, install } from '@/plugins/beforeinstallprompt'
  import { isSupportCache } from '@/utils/app/cache'

  export default {
    components: { NotificationsHeader },
    data: () => ({
      isSupportCache: isSupportCache()
    }),
    computed: {
      already () {
        return state.already
      },
      logo () {
        if (this.$vuetify.breakpoint.xsOnly) return `/icon/icon.svg`
        return `/icon/name.svg`
      }
    },
    methods: {
      install,
      ...mapMutations({
        toggleNav: 'app/TOGGLE_NAV',
        showProfile: 'app/SHOW_PROFILE'
      }),
      async logout() {
        await this.$store.dispatch('auth/logout')
        this.$router.push('/login')
      }
    }
  }
