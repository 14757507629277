import Vue from 'vue'

class ShowDetails {
  #store;

  constructor(store) {
    this.#store = store
  }

  get employeeData() {
    return this.#store.state.details.employee
  }
  employee(employee) {
    this.#store.commit('details/SET_EMPLOYEE', employee)
  }


  get participantData() {
    return this.#store.state.details.participant
  }
  participant(participant) {
    this.#store.commit('details/SET_PARTICIPANT', participant)
  }

  get stageData() {
    return this.#store.state.details.stage
  }
  stage(stage) {
    this.#store.commit('details/SET_STAGE', stage)
  }
}

export default async ({store}) => {
  const showDetails = new ShowDetails(store)
  Vue.prototype.$showDetails = showDetails;
  Vue.$showDetails = showDetails
}
