import { render, staticRenderFns } from "./help-icon.vue?vue&type=template&id=7c8f14e8&functional=true"
import script from "./help-icon.vue?vue&type=script&lang=js"
export * from "./help-icon.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {STooltip: require('C:/Portfolio/BaltehServis/Smarteh/client/components/s/tooltip.vue').default})
