import Vue from 'vue'

const components = new Map

const handler = (event) => {
  const list = [...components.entries()]
  for (let i = list.length - 1; i >= 0; i--) {
    if (
      list[i][1] === undefined
      || typeof list[i][1] === 'function'
      && !!list[i][1].call()
    ) {
      list.splice(i + 1).forEach(i => components.delete(i[0]))
      event.preventDefault()
      event.returnValue = ''
      if (!components.size)
        window.removeEventListener('beforeunload', handler)
      return
    }
  }
}

/**
 * Registration component
 * @param component Vue Component (like `this`)
 * @param callback Callback function where your logic. If you dont need route leave, function need return true.
 */
const blockUnloadWindow = (component, callback = undefined) => {
  if (!components.length)
    window.addEventListener('beforeunload', handler)
  components.set(component, callback)
  component.$once('hook:beforeDestroy', () => {
    components.delete(component)
  })
}

/**
 * Unregister component
 * @param component Vue Component (like `this`)
 */
const unBlockUnloadWindow = (component) => {
  components.delete(component)
  if (!components.size)
    window.removeEventListener('beforeunload', handler)
}

export default function () {

  // this.$blockUnloadWindow(this, () => { ... })
  Vue.prototype.$blockUnloadWindow = blockUnloadWindow

  // this.$unBlockUnloadWindow(this)
  Vue.prototype.$unBlockUnloadWindow = unBlockUnloadWindow
}
