
import { VIcon } from 'vuetify/lib/components'
import VTooltip from '@/components/v/tooltip'
import { mergeData, mergeProps } from '@/utils/mergeData'
import Vue from 'vue'

export default Vue.extend({
  name: 's-archived-icon',
  inheritAttrs: false,
  props: {
    ...VIcon.options.$_wrapperFor.options.props,
    ...VTooltip.options.props,
    icon: {
      type: String,
      default: '$archive',
    },
    color: {
      type: String,
      default: 'indigo',
    },
    top: mergeProps(VTooltip.options.props.top, {
      default: true
    }),
    openDelay: mergeProps(VTooltip.options.props.openDelay, {
      default: 300
    }),
    tooltip: {
      type: String,
      default () {
        return this.$t('ST.archived')
      }
    },
    iconClass: [Array, String, Object],
    iconStyle: [Array, String, Object],
  },
  render () {
    return this.$createElement(
      VTooltip,
      {
        props: this.$props,
        scopedSlots: {
          activator: (props) => this.$createElement(
            VIcon,
            mergeData(
              props,
              {
                props: this.$props,
                attrs: this.$attrs,
                on: this.$listeners,
                style: this.iconStyle,
                class: this.iconClass,
              },
            ),
            this.icon
          )
        }
      },
      this.tooltip,
    )
  }
})
