export const NOT_SYMBOL = '!'
export const LOCAL_QUERY_NOT_SYMBOL = 'not_'
export const NULL_SYMBOL = 'null'

export const OPERATORS = [NOT_SYMBOL]

export function parseArrayFilter (value) {
  if (!Array.isArray(value)) return value
  value = value.slice()
  let not = false
  if (value[0] && typeof value[0] === 'string' &&
    value[0].startsWith(NOT_SYMBOL)) {
    not = true
    value[0] = value[0].slice(1)
  }
  return {
    value,
    not,
  }
}

export function getDefaultFilterByMap (map) {
  const result = {}
  for (const key in map) {
    switch (map[key]) {
      case String:
      case Boolean: {
        result[key] = null
        break
      }
      case Array:
      case Date:
      case Number: {
        result[key] = []
        break
      }
    }
  }
  return result
}

export function filterParametersFilter (filter, map) {
  filter = { ...filter }
  for (const key in filter) {
    if (!key in map) {
      delete filter[key]
      continue
    }
    switch (map[key]) {
      case String: {
        if (filter[key]) continue
        break
      }
      case Boolean: {
        if (typeof filter[key] === 'boolean') {
          continue
        }
        break
      }
      case Array:
      case Date:
      case Number: {
        if (
          Array.isArray(filter[key]) &&
          filter[key].length &&
          !(
            filter[key].length === 1
            && filter[key][0] === NOT_SYMBOL
          )
        ) {
          continue
        }
        break
      }
    }
    delete filter[key]
  }
  return filter
}

export const filterToQuery = (filter, map, local = false) => {
  if (typeof filter !== 'object') return {}
  const params = {}
  for (const key in map) {
    const type = map[key]
    if (!key in filter) continue
    if ([Array, Number].includes(type)) {
      if (!Array.isArray(filter[key]) || !filter[key].length) continue
      const val = filter[key].map(v => v === null ? NULL_SYMBOL : v)
      if (val[0] === NOT_SYMBOL) {
        val.shift()
        params[key] = (local ? LOCAL_QUERY_NOT_SYMBOL : NOT_SYMBOL) +
          val.join(',')
      } else {
        params[key] = val.join(',')
      }
    } else if (type === String) {
      if (!filter[key]) continue
      params[key] = filter[key]
    } else if (type === Date) {
      if (!filter[key]) continue
      if (Array.isArray(filter[key])) {
        if (!filter[key].length) continue
        params[key] = filter[key].join('_')
      } else {
        params[key] = filter[key]
      }
    } else if (type === Boolean) {
      if (typeof filter[key] !== 'boolean') continue
      params[key] = Number(filter[key])
    }
  }
  return params
}

export const filterFromQuery = (query, map) => {
  if (typeof query !== 'object') return {}
  const allowedValues = [NULL_SYMBOL, NOT_SYMBOL, LOCAL_QUERY_NOT_SYMBOL]
  const params = {}
  for (const key in map) {
    const type = map[key]
    if (!key in query) continue
    if (!query[key]) continue
    if (type === Array || type === Number) {
      params[key] = Array.isArray(query[key])
        ? [...query[key]]
        : query[key].split(',')
      for (const not of [NOT_SYMBOL, LOCAL_QUERY_NOT_SYMBOL]) {
        if (params[key][0].startsWith(not)) {
          params[key][0] = params[key][0].slice(not.length)
          params[key].unshift(NOT_SYMBOL)
        }
      }
      if (type === Number) {
        params[key] = params[key].map(
          v => !allowedValues.includes(v)
            ? +v
            : v,
        ).filter(v => v > 0 || allowedValues.includes(v))
      }
    } else if (type === String) {
      params[key] = query[key]
    } else if (type === Date) {
      params[key] = query[key].split('_')
      if (params[key].length === 1) {
        params[key] = params[key][0]
      }
    } else if (type === Boolean) {
      params[key] = ['1', 'true', 1, true].includes(query[key])
    }
  }
  return params
}

export function parseArray (array, filter_null = false) {
  return array.reduce((o, i) => {
    if (i === NOT_SYMBOL) {
      o.not = true
    } else if (i === NULL_SYMBOL) {
      o.null = true
      if (!filter_null) {
        o.array.push(i)
      }
    } else {
      o.array.push(i)
    }
    return o
  }, {
    array: [],
    not: false,
    null: false,
  })
}

/**
 *
 * @param {Array} array
 * @param {Boolean} not
 * @param {Boolean} has_null
 */
export function completeArray (array, not = false, has_null = false) {
  if (!array.length) return array
  array = array.slice()
  if (has_null) {
    array.unshift(NULL_SYMBOL)
  }
  if (not) {
    array.unshift(NOT_SYMBOL)
  }
  return array
}
