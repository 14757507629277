let request = null;

export default {

  async fetch({ commit, state}, params = {}) {
    try {
      if (request) request.cancel()
      request = this.$axios.CancelToken.source();
      commit('SET_ERROR', { orders: null })
      commit('SET_LOADING', { orders: true })
      const config = {
        params: filterToQuery(state.filter) || {},
        cancelToken: request.token
      }
      if (params.offset)
        config.params.offset = params.offset
      const orders = await this.$axios.get(`materials/orders`, config)
      if (params.offset)
        commit('ADD_ORDERS', orders)
      else
        commit('SET_ORDERS', orders)
      return orders
    } catch (e) {
      if (!this.$axios.isCancel(e)) {
        commit('SET_ERROR', { orders: e })
        throw e
      }
    } finally {
      commit('SET_LOADING', { orders: false })
    }
  },
}

export const filterToQuery = (filter) => {
  const query = {}

  return query
}

export const filterFromQuery = (query) => {
  const filter = {}

  return filter
}
