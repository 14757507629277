
import { VTooltip } from 'vuetify/lib/components'

export default VTooltip.extend({
  beforeUnmount() {
    this.$refs.content?.remove()
  },
  deactivated() {
    this.isActive = false
  },
})
