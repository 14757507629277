import Vue from 'vue';

export const reset = () => {
  Object.assign(state, {
    data: null,
    timestamp: null
  })
}

export const state = Vue.observable({
  data: null,
  timestamp: null,
  reset
});

Vue.prototype.$shareTarget = state

navigator.serviceWorker.addEventListener('message', (event) => {
  if (typeof event.data === 'object' && event.data.action === 'share-target') {
    state.data = event.data.data
    state.timestamp = new Date()
    console.log('ShareTarget:', event.data.data);
  }
});
