import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _0f7cbfbe = () => interopDefault(import('..\\pages\\companies\\index.vue' /* webpackChunkName: "pages/companies/index" */))
const _3ba17792 = () => interopDefault(import('..\\pages\\employees\\index.vue' /* webpackChunkName: "pages/employees/index" */))
const _45b273e4 = () => interopDefault(import('..\\pages\\groups\\index.vue' /* webpackChunkName: "pages/groups/index" */))
const _90a23262 = () => interopDefault(import('..\\pages\\login.vue' /* webpackChunkName: "pages/login" */))
const _5e00d530 = () => interopDefault(import('..\\pages\\materials\\index.vue' /* webpackChunkName: "pages/materials/index" */))
const _1c8d83c8 = () => interopDefault(import('..\\pages\\objects\\index.vue' /* webpackChunkName: "pages/objects/index" */))
const _168a5ce4 = () => interopDefault(import('..\\pages\\tasks\\index.vue' /* webpackChunkName: "pages/tasks/index" */))
const _a03bc9b0 = () => interopDefault(import('..\\pages\\warehouses\\index.vue' /* webpackChunkName: "pages/warehouses/index" */))
const _c0ac5bfc = () => interopDefault(import('..\\pages\\accounting\\import-tasks-prices.vue' /* webpackChunkName: "pages/accounting/import-tasks-prices" */))
const _88369e76 = () => interopDefault(import('..\\pages\\accounting\\invoices.vue' /* webpackChunkName: "pages/accounting/invoices" */))
const _2be0075d = () => interopDefault(import('..\\pages\\accounting\\materials.vue' /* webpackChunkName: "pages/accounting/materials" */))
const _16677ff1 = () => interopDefault(import('..\\pages\\debug\\icons.vue' /* webpackChunkName: "pages/debug/icons" */))
const _79c75bfa = () => interopDefault(import('..\\pages\\debug\\themes.vue' /* webpackChunkName: "pages/debug/themes" */))
const _cffeb8a4 = () => interopDefault(import('..\\pages\\materials\\categories.vue' /* webpackChunkName: "pages/materials/categories" */))
const _8287821c = () => interopDefault(import('..\\pages\\materials\\print_codes.vue' /* webpackChunkName: "pages/materials/print_codes" */))
const _3524a9fe = () => interopDefault(import('..\\pages\\paid_incoming_supplier_invoices\\import.vue' /* webpackChunkName: "pages/paid_incoming_supplier_invoices/import" */))
const _8983ab46 = () => interopDefault(import('..\\pages\\reports\\accounting_materials_transactions.vue' /* webpackChunkName: "pages/reports/accounting_materials_transactions" */))
const _78655124 = () => interopDefault(import('..\\pages\\reports\\departures.vue' /* webpackChunkName: "pages/reports/departures" */))
const _9677d124 = () => interopDefault(import('..\\pages\\reports\\departures_price.vue' /* webpackChunkName: "pages/reports/departures_price" */))
const _58590751 = () => interopDefault(import('..\\pages\\reports\\my.vue' /* webpackChunkName: "pages/reports/my" */))
const _18cfdb8a = () => interopDefault(import('..\\pages\\reports\\stats.vue' /* webpackChunkName: "pages/reports/stats" */))
const _1ac6c4a2 = () => interopDefault(import('..\\pages\\reports\\warehouses_materials.vue' /* webpackChunkName: "pages/reports/warehouses_materials" */))
const _00bb6662 = () => interopDefault(import('..\\pages\\reports\\warehouses_transactions.vue' /* webpackChunkName: "pages/reports/warehouses_transactions" */))
const _0fd4f9dc = () => interopDefault(import('..\\pages\\reports\\workload.vue' /* webpackChunkName: "pages/reports/workload" */))
const _d7f0002c = () => interopDefault(import('..\\pages\\tasks\\activity_codes.vue' /* webpackChunkName: "pages/tasks/activity_codes" */))
const _1ae89be0 = () => interopDefault(import('..\\pages\\tasks\\categories.vue' /* webpackChunkName: "pages/tasks/categories" */))
const _b2e7b79c = () => interopDefault(import('..\\pages\\warehouses\\me.vue' /* webpackChunkName: "pages/warehouses/me" */))
const _7b48c18e = () => interopDefault(import('..\\pages\\sync\\prengi\\categories.vue' /* webpackChunkName: "pages/sync/prengi/categories" */))
const _4c6b9512 = () => interopDefault(import('..\\pages\\sync\\prengi\\objects.vue' /* webpackChunkName: "pages/sync/prengi/objects" */))
const _13f31fdc = () => interopDefault(import('..\\pages\\sync\\prengi\\statuses.vue' /* webpackChunkName: "pages/sync/prengi/statuses" */))
const _59cd979a = () => interopDefault(import('..\\pages\\sync\\prengi\\workers.vue' /* webpackChunkName: "pages/sync/prengi/workers" */))
const _0a783c78 = () => interopDefault(import('..\\pages\\tasks\\departures\\is_billed.vue' /* webpackChunkName: "pages/tasks/departures/is_billed" */))
const _23f17890 = () => interopDefault(import('..\\pages\\index.vue' /* webpackChunkName: "pages/index" */))
const _7c886acb = () => interopDefault(import('..\\pages\\companies\\_company_id.vue' /* webpackChunkName: "pages/companies/_company_id" */))
const _4e6ed790 = () => interopDefault(import('..\\pages\\employees\\_employee_id.vue' /* webpackChunkName: "pages/employees/_employee_id" */))
const _47589b68 = () => interopDefault(import('..\\pages\\groups\\_group_id.vue' /* webpackChunkName: "pages/groups/_group_id" */))
const _0feda086 = () => interopDefault(import('..\\pages\\materials\\_material_id.vue' /* webpackChunkName: "pages/materials/_material_id" */))
const _6b8b4ad4 = () => interopDefault(import('..\\pages\\objects\\_object_id.vue' /* webpackChunkName: "pages/objects/_object_id" */))
const _abfc426c = () => interopDefault(import('..\\pages\\tasks\\_task_id.vue' /* webpackChunkName: "pages/tasks/_task_id" */))
const _69059cac = () => interopDefault(import('..\\pages\\warehouses\\_warehouse_uuid.vue' /* webpackChunkName: "pages/warehouses/_warehouse_uuid" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/companies",
    component: _0f7cbfbe,
    name: "companies"
  }, {
    path: "/employees",
    component: _3ba17792,
    name: "employees"
  }, {
    path: "/groups",
    component: _45b273e4,
    name: "groups"
  }, {
    path: "/login",
    component: _90a23262,
    name: "login"
  }, {
    path: "/materials",
    component: _5e00d530,
    name: "materials"
  }, {
    path: "/objects",
    component: _1c8d83c8,
    name: "objects"
  }, {
    path: "/tasks",
    component: _168a5ce4,
    name: "tasks"
  }, {
    path: "/warehouses",
    component: _a03bc9b0,
    name: "warehouses"
  }, {
    path: "/accounting/import-tasks-prices",
    component: _c0ac5bfc,
    name: "accounting-import-tasks-prices"
  }, {
    path: "/accounting/invoices",
    component: _88369e76,
    name: "accounting-invoices"
  }, {
    path: "/accounting/materials",
    component: _2be0075d,
    name: "accounting-materials"
  }, {
    path: "/debug/icons",
    component: _16677ff1,
    name: "debug-icons"
  }, {
    path: "/debug/themes",
    component: _79c75bfa,
    name: "debug-themes"
  }, {
    path: "/materials/categories",
    component: _cffeb8a4,
    name: "materials-categories"
  }, {
    path: "/materials/print_codes",
    component: _8287821c,
    name: "materials-print_codes"
  }, {
    path: "/paid_incoming_supplier_invoices/import",
    component: _3524a9fe,
    name: "paid_incoming_supplier_invoices-import"
  }, {
    path: "/reports/accounting_materials_transactions",
    component: _8983ab46,
    name: "reports-accounting_materials_transactions"
  }, {
    path: "/reports/departures",
    component: _78655124,
    name: "reports-departures"
  }, {
    path: "/reports/departures_price",
    component: _9677d124,
    name: "reports-departures_price"
  }, {
    path: "/reports/my",
    component: _58590751,
    name: "reports-my"
  }, {
    path: "/reports/stats",
    component: _18cfdb8a,
    name: "reports-stats"
  }, {
    path: "/reports/warehouses_materials",
    component: _1ac6c4a2,
    name: "reports-warehouses_materials"
  }, {
    path: "/reports/warehouses_transactions",
    component: _00bb6662,
    name: "reports-warehouses_transactions"
  }, {
    path: "/reports/workload",
    component: _0fd4f9dc,
    name: "reports-workload"
  }, {
    path: "/tasks/activity_codes",
    component: _d7f0002c,
    name: "tasks-activity_codes"
  }, {
    path: "/tasks/categories",
    component: _1ae89be0,
    name: "tasks-categories"
  }, {
    path: "/warehouses/me",
    component: _b2e7b79c,
    name: "warehouses-me"
  }, {
    path: "/sync/prengi/categories",
    component: _7b48c18e,
    name: "sync-prengi-categories"
  }, {
    path: "/sync/prengi/objects",
    component: _4c6b9512,
    name: "sync-prengi-objects"
  }, {
    path: "/sync/prengi/statuses",
    component: _13f31fdc,
    name: "sync-prengi-statuses"
  }, {
    path: "/sync/prengi/workers",
    component: _59cd979a,
    name: "sync-prengi-workers"
  }, {
    path: "/tasks/departures/is_billed",
    component: _0a783c78,
    name: "tasks-departures-is_billed"
  }, {
    path: "/",
    component: _23f17890,
    name: "index"
  }, {
    path: "/companies/:company_id",
    component: _7c886acb,
    name: "companies-company_id"
  }, {
    path: "/employees/:employee_id",
    component: _4e6ed790,
    name: "employees-employee_id"
  }, {
    path: "/groups/:group_id",
    component: _47589b68,
    name: "groups-group_id"
  }, {
    path: "/materials/:material_id",
    component: _0feda086,
    name: "materials-material_id"
  }, {
    path: "/objects/:object_id",
    component: _6b8b4ad4,
    name: "objects-object_id"
  }, {
    path: "/tasks/:task_id",
    component: _abfc426c,
    name: "tasks-task_id"
  }, {
    path: "/warehouses/:warehouse_uuid",
    component: _69059cac,
    name: "warehouses-warehouse_uuid"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
