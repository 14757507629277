export default function ({route, store, i18n}) {
  if(route.meta[0] && route.meta[0].breadcrumbs !== false) {
    const breadcrumbs = route.meta[0].breadcrumbs || []
    const to = route.path.length > 1
      ? route.path.replace(/\/$/, '')
      : route.path
    if (route.meta[0].breadcrumb) {
      if (typeof route.meta[0].breadcrumb === 'object') {
        breadcrumbs.push(route.meta[0].breadcrumb)
      } else {
        breadcrumbs.push({
          text: route.meta[0].breadcrumb,
          to,
        })
      }
    }
    if (
      !breadcrumbs.length
      && i18n.te(`PG.name.${to}`)
      && route.path.length > 1
    ) {
      breadcrumbs.push({
        to,
      })
    }
    store.commit('app/SET_BREADCRUMBS', breadcrumbs)
  }
  if(route.meta[0] && route.meta[0].show_breadcrumbs === false)
    store.commit('app/SHOW_BREADCRUMBS', false)
  else if(!store.state.app.show_breadcrumbs)
    store.commit('app/SHOW_BREADCRUMBS', true)
}
