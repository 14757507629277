
import SAutocomplete from '@/components/s/autocomplete'
import {
  VAutocomplete,
  VListItemContent,
  VListItemSubtitle,
  VListItemTitle,
} from 'vuetify/lib/components'
import VArchivedIcon from '@/components/s/archived-icon'
import { mergeProps } from '@/utils/mergeData'
import { filterItem } from '@/utils/smarteh/objects/filter'
import { getPropertyFromItem } from 'vuetify/lib/util/helpers'
import noDataTextLoading from '@/mixins/inputs/noDataTextLoading'

const noDataTextLoadingMixin = noDataTextLoading(VAutocomplete.options.computed.listData)
export default SAutocomplete.extend({
  name: 'object-autocomplete',
  props: {
    itemText: mergeProps(SAutocomplete.options.props.itemText, {
      default: 'name',
    }),
    itemValue: mergeProps(SAutocomplete.options.props.itemValue, {
      default: 'object_id',
    }),
    label: {
      type: [String, Array],
      default: 'ET.object.PL',
    },
    icon: {
      type: String,
      default: '$object',
    },
    outlined: mergeProps(SAutocomplete.options.props.outlined, {
      default: true,
    }),
    autoSelectFirst: mergeProps(SAutocomplete.options.props.autoSelectFirst, {
      default: true,
    }),
    filter: mergeProps(SAutocomplete.options.props.filter, {
      default: filterItem,
    }),
  },
  computed: {
    listData () {
      const data = noDataTextLoadingMixin.options.computed.listData.call(this)
      if (!data.scopedSlots.item) {
        data.scopedSlots.item =
          ({ item }) => this.$createElement(
            VListItemContent,
            [
              this.$createElement(
                VListItemTitle,
                [
                  String(getPropertyFromItem(item, this.itemText, item)),
                  getPropertyFromItem(item, this.itemArchived) ? this.$createElement(VArchivedIcon, {
                    props: {
                      xSmall: true,
                    },
                    class: 'mt-n1 ml-1',
                  }) : null,
                ],
              ),
              item.code ? this.$createElement(VListItemSubtitle, item.code) : null,
              item.address ? this.$createElement(VListItemSubtitle, item.address) : null,
            ],
          )
      }
      return data
    },
  },
})
