
import SSelect from '@/components/s/select'
import { mergeProps } from '@/utils/mergeData'

export default SSelect.extend({
  name: 'task-activity-code-select',
  props: {
    itemText: mergeProps(SSelect.options.props.itemText, {
      default: 'name',
    }),
    itemValue: mergeProps(SSelect.options.props.itemValue, {
      default: 'activity_code_id',
    }),
    label: {
      type: [String, Array],
      default: 'ET.task_activity_code.PL',
    },
    icon: {
      type: String,
      default: '$task_activity_code',
    },
    outlined: mergeProps(SSelect.options.props.outlined, {
      default: true,
    }),
    autoSelectFirst: mergeProps(SSelect.options.props.autoSelectFirst, {
      default: true,
    }),
  },
})
