
import { VTextField, VAutocomplete, VSelect } from 'vuetify/lib/components'
import { ClickOutside } from 'vuetify/lib/directives'
import Dependent from 'vuetify/lib/mixins/dependent'

export default VTextField.extend({
  name: 'v-text-field-with-menu',
  mixins: [
    Dependent
  ],

  directives: {
    ClickOutside
  },
  props: {
    menuProps: VAutocomplete.options.props.menuProps,
    attach: VAutocomplete.options.props.attach,
  },
  data () {
    return {
      isMenuActive: false,
      menuIsBooted: false,
    }
  },
  computed: {
    classes: VSelect.options.computed.classes,
    menuCanShow: VSelect.options.computed.menuCanShow,
    $_menuProps: VAutocomplete.options.computed.$_menuProps,
    directives: VSelect.options.computed.directives,
  },
  methods: {
    blur (e) {
      VTextField.options.methods.blur.call(this, e)
      this.isMenuActive = false
      this.isFocused = false
    },
    genDefaultSlot () {
      return [
        this.genFieldset(),
        this.$createElement('div', {
          staticClass: 'v-select__slot',
          directives: this.directives,
        }, [
          this.genTextFieldSlot(),
          this.genClearIcon(),
          this.genIconSlot(),
        ]),
        this.genMenu(),
        this.genProgress(),
      ]
    },
    genMenu () {
      const menu = VSelect.options.methods.genMenu.call(this)
      delete menu.componentOptions?.listeners?.scroll
      return menu
    },
    genList () {
      return this.$scopedSlots.menu({ isMenuActive: this.isMenuActive })
    },
    onBlur: VSelect.options.methods.onBlur,
    getContent: VSelect.options.methods.getContent,
    onMouseUp: VSelect.options.methods.onMouseUp,
    onClick: VSelect.options.methods.onClick,
    isAppendInner: VSelect.options.methods.isAppendInner,
    onEscDown: VSelect.options.methods.onEscDown,
    closeConditional: VSelect.options.methods.closeConditional,
  }
})
