import {defaultData} from "./state";

export default {
  SET_SHOW_FILTER(state, bool) {
    state.show_filter = !!bool
  },
  RESET(state) {
    Object.assign(state, defaultData())
  },

  SET_ORDERS(state, orders = []) {
    state.list = orders
  },
  ADD_ORDERS(state, orders) {
    state.list = state.list.concat(orders)
  },
  SET_FILTER(state, filter = {}) {
    state.filter = filter
  },
  SET_LOADING(state, loading = {}) {
    state.loading = Object.assign({}, state.loading, loading)
  },
  SET_ERROR(state, error = {}) {
    state.error = Object.assign({}, state.error, error)
  },
}
