import { render, staticRenderFns } from "./filter.vue?vue&type=template&id=28c9a0c0"
import script from "./filter.vue?vue&type=script&lang=js"
export * from "./filter.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {VIconLabel: require('C:/Portfolio/BaltehServis/Smarteh/client/components/v/icon-label.vue').default,VTreeSelect: require('C:/Portfolio/BaltehServis/Smarteh/client/components/v/tree-select.js').default})
