
import { mapGetters } from 'vuex'

export default {
  computed: {
    menu () {
      const menu = []
      menu.push({
        url: '/tasks',
        icon: '$task',
      })
      if (this.hasPermission('warehouses.view')) {
        menu.push({
          url: '/warehouses/me',
          icon: '$warehouse',
        })
        menu.push({
          url: '/warehouses',
          icon: '$warehouse',
        })
      }
      if (this.hasPermission('materials.view')) {
        menu.push({
          url: '/materials',
          icon: '$material',
        })
      }
      if (this.hasPermission('materials_categories.edit')) {
        menu.push({
          url: '/materials/categories',
          icon: '$material_category',
        })
      }
      const reports = [
        {
          url: '/reports/my',
          icon: '$user',
        },
        {
          url: '/reports/departures',
          icon: '$task_departures',
        },
        {
          url: '/reports/workload',
          icon: '$work_time',
        },
      ]
      if (this.hasPermission('reports.warehouses_materials')) {
        reports.push({
          url: '/reports/warehouses_materials',
          icon: '$material',
        })
      }
      if (this.hasPermission('reports.warehouses_transactions')) {
        reports.push({
          url: '/reports/warehouses_transactions',
          icon: '$employees_materials_transaction',
        })
      }
      if (this.hasPermission('reports.stats')) {
        reports.push({
          url: '/reports/stats',
          icon: '$task',
        })
      }
      if (this.hasPermission('reports.departures_price')) {
        reports.push({
          url: '/reports/departures_price',
          icon: '$bill',
        })
      }
      if (this.hasPermission('reports.accounting_materials_transactions')) {
        reports.push({
          url: '/reports/accounting_materials_transactions',
          icon: '$accounting_material',
        })
      }
      if (reports.length) {
        menu.push({
          url: '/reports',
          children: reports,
        })
      }
      const accounting = []
      if (this.hasPermission('accounting_invoices.view')) {
        accounting.push({
          url: '/accounting/invoices',
          icon: '$accounting_invoice'
        })
      }
      if (this.hasPermission('accounting_materials.view')) {
        accounting.push({
          url: '/accounting/materials',
          icon: '$accounting_material'
        })
      }
      if (this.hasPermission('accounting_tasks.create')) {
        accounting.push({
          url: '/accounting/import-tasks-prices',
          icon: '$task'
        })
      }
      if (this.hasPermission('tasks_departures.is_billed')) {
        accounting.push({
          url: '/tasks/departures/is_billed',
          icon: '$bill',
        })
      }
      if (this.hasPermission('paid_incoming_supplier_invoices.import')) {
        accounting.push({
          url: '/paid_incoming_supplier_invoices/import',
          icon: '$bill',
        })
      }
      if (accounting.length) {
        menu.push({
          url: '/accounting',
          children: accounting,
        })
      }
      if (this.hasPermission('employees.view')) {
        menu.push({
          url: '/employees',
          icon: '$employee',
        })
      }
      if (this.hasPermission('employees_groups.view')) {
        menu.push({
          url: '/groups',
          icon: '$employee_group',
        })
      }
      if (this.hasPermission('objects.view')) {
        menu.push({
          url: '/objects',
          icon: '$object',
        })
      }
      if (this.hasPermission('companies.view')) {
        menu.push({
          url: '/companies',
          icon: '$company',
        })
      }
      if (this.hasPermission('tasks_categories.edit')) {
        menu.push({
          url: '/tasks/categories',
          icon: '$task_category',
        })
      }
      if (this.hasPermission('tasks_activity_codes.edit')) {
        menu.push({
          url: '/tasks/activity_codes',
          icon: '$task_activity_code',
        })
      }
      if (this.hasPermission('sync.prengi')) {
        menu.push({
          url: '/sync/prengi',
          children: [
            {
              url: '/sync/prengi/workers',
              icon: '$employee',
            },
            {
              url: '/sync/prengi/objects',
              icon: '$object',
            },
            {
              url: '/sync/prengi/categories',
              icon: '$task_category',
            },
            {
              url: '/sync/prengi/statuses',
              icon: '$task_category',
            },
            {
              url: '/sync/prengi/log',
            },
          ],
        })
      }
      return menu
    },
    nav: {
      get () {
        return this.$store.state.app.nav
      },
      set (val) {
        this.$store.commit('app/SET_NAV', val)
      },
    },
    theme: {
      get () {
        return this.$store.state.profile.theme.mode
      },
      set (val) {
        this.$store.commit('profile/SET_THEME_MODE', val)
      },
    },
    themeStatus () {
      switch (this.theme) {
        case 'auto':
          return 'Автоматично'
        case 'system':
          return 'Тема пристрою'
        case 'time':
          return 'За розкладом'
        case 'light':
          return 'Світла тема'
        case 'dark':
          return 'Темна тема'
        default:
          return `${this.theme}`
      }
    },
    themeIcon () {
      switch (this.theme) {
        case 'auto':
          return '$theme_auto'
        case 'system':
          return '$system'
        case 'time':
          return '$clock'
        case 'light':
          return '$theme_light'
        case 'dark':
          return '$theme_dark'
        default:
          return `$unknown`
      }
    },
    ...mapGetters({
      hasPermission: 'profile/hasPermission',
    }),
  },
  methods: {
    changeTheme () {
      this.theme = ({
        'light': 'dark',
        'dark': 'auto',
      })[this.theme] || 'light'
      this.$nextTick(() => {
        this.$refs.themeState && this.$refs.themeState.updateDimensions()
      })
    },
    feedback () {
      if (this.$vuetify.breakpoint.mdAndDown)
        this.nav = false
    },
  },
}
