import Vue from 'vue'

const localStorageToken = 'smarteh_notification_device'
const intervalForSendUpdateToken = 30 * 24 * 60 * 60 * 1000

const defaultData = () => ({
  enabled: null,
  employee_id: null,
  last_sent_token_timestamp: 0,
})

export default ({ $axios, $fire, store }, inject) => {

  function getConfig () {
    try {
      return JSON.parse(window?.localStorage?.getItem(localStorageToken) || 'null') || defaultData()
    } catch (e) {
      return defaultData()
    }
  }

  function saveConfig (config) {
    window?.localStorage?.setItem(localStorageToken, JSON.stringify(config || defaultData()))
  }

  let storeWatchEmployeeId

  const plugin = new Vue({
    data: getConfig,
    created () {
      if (this.isSubscribeDenied()) {
        this.disable()
      } else if (this.enabled && this.needTokenSend()) {
        this.getToken()?.then(this.sendTokenToServer)
      }
      storeWatchEmployeeId = store.watch(
        (state, getters) => getters.employee_id,
        this.onEmployeeID,
        {
          immediate: true,
        }
      )
    },
    beforeDestroy () {
      storeWatchEmployeeId()
    },
    methods: {
      permission () {
        return window?.Notification?.permission
      },
      hasPermission () {
        return this.permission() === 'granted'
      },
      enable () {
        if (!this.canSubscribe()) return false
        return this.getToken()?.then((token) => {
          this.enabled = true
          this.employee_id = store.getters['employee_id']
          return this.sendTokenToServer(token)
        })?.catch(() => {
          this.enabled = false
        }) || false
      },
      getToken () {
        return window?.$workbox?.then(workbox => workbox.register())
          .then(workbox => $fire.messaging.getToken({
            vapidKey: $fire.messaging.vapidKey,
            serviceWorkerRegistration: workbox
          }))
      },
      deleteToken () {
        return $fire.messaging.deleteToken()
      },
      disable () {
        if (!this.enabled) return
        Object.assign(this, defaultData())
        return this.deleteToken().catch(() => {})
      },
      canSubscribe () {
        return ['granted', 'default'].includes(this.permission())
      },
      isSubscribeDenied () {
        return !this.canSubscribe()
      },
      sendTokenToServer (token) {
        return $axios.post('/notifications/devices', {
          token,
        }).then(() => {
          this.last_sent_token_timestamp = Date.now()
        })
      },
      needTokenSend () {
        return this.last_sent_token_timestamp + intervalForSendUpdateToken < Date.now()
      },
      canShowPrompt () {
        return this.canSubscribe() && this.enabled === null
      },
      hidePrompt () {
        this.enabled = false
      },
      isSupport () {
        return true
      },
      onEmployeeID (v) {
        if (v && this.enabled && this.employee_id !== v) {
          this.disable()
        }
      }
    },
    watch: {
      '$data': {
        handler: saveConfig,
        deep: true,
      }
    }
  })

  inject('notification', plugin)
}
