import {get} from "lodash";

export const normalize = (str) => String(str || '').toLocaleLowerCase()

const searchInText = (itemText, query) => !query || normalize(itemText).indexOf(query) > -1
export const searchText = (itemText, query) => searchInText(itemText, normalize(query))

const AllOf = (filters) => (...args) => filters.findIndex(f => !f(...args)) === -1
const AnyOf = (filters) => (...args) => filters.findIndex(f => f(...args)) > -1
/**
 *
 * @param {Function|Array<Function>>} f
 * @returns {Function}
 */
export const getFilter = (f) => typeof f === 'function'? f: AllOf(f);
export const filterArrayByFilter = (array, filters) => array.filter((...args) => getFilter(filters)(...args))

/**
 *
 * @param {Object} item
 * @param {Array<String>} props
 * @param {String} query
 */
export const multiSearchTextFilter = (props, query) =>
    (query = normalize(query))?
    AnyOf(props.map((prop) => (item) => searchInText(get(item, prop), query))): true
export const multiSearchText = (item, props, query) =>
    (query = normalize(query))?
        AnyOf(props.map((prop) => () => searchInText(get(item, prop), query)))(): true
