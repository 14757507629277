

const DEVICE_ID_KEY = 's-scanner_device-id'
const LIGHT_KEY = 's-scanner_light-on'
export const isSupport = () => navigator && navigator.mediaDevices && 'enumerateDevices' in navigator.mediaDevices
export default {
  name: 's-scanner',
  props: {
    active: {
      type: Boolean,
      default: true,
    },
  },
  data () {
    return {
      isActive: false,
      reader: null,
      readerInstance: null,
      controlers: null,
      isSupport: isSupport(),
      devices: [],
      deviceId: localStorage.getItem(DEVICE_ID_KEY) || undefined,
      supportLight: false,
      lightOn: false,
      permissionDenied: false,
    }
  },
  created () {
    this.reader = import('@zxing/browser').then(({ BrowserMultiFormatReader }) => BrowserMultiFormatReader)
    this.readerInstance = this.reader.then(v => new v())
    this.$emit('isSupport', this.isSupport)
  },
  mounted () {
    this.getVideoInputDevices().then(() => this.restart())
  },
  beforeDestroy () {
    this.stop()
  },
  methods: {
    async getVideoInputDevices () {
      const devices = await (await this.reader).listVideoInputDevices()
      this.devices = devices && devices.filter(d => d.kind === 'videoinput') || []
      if (this.devices && this.devices[0]) {
        this.deviceId = this.deviceId
          && this.devices.find(d => d.deviceId === this.deviceId)
          && this.deviceId
          || this.devices[0].deviceId
      }
    },
    async start () {
      const instance = await this.readerInstance
      this.stop()
      try {
        this.controlers = await instance.decodeFromVideoDevice(
          this.deviceId,
          this.$refs.scanner,
          (result, error) => {
            if (result) {
              this.$emit('result', result)
              this.$fire.analytics.logEvent('scanner', {
                result: result.getText(),
                method: (this.devices.find(d => d.deviceId === this.deviceId) || {}).label,
              })
            }
            if (error) {
              this.$emit('error', error)
            }
          },
        )
        this.supportLight = !!this.controlers.switchTorch
        if (this.supportLight) {
          this.lightOn = !!(+localStorage.getItem(LIGHT_KEY) || 0)
        }
        this.isActive = true
        this.permissionDenied = false
      } catch (e) {
        if (e.message.includes('Permission denied')) {
          this.permissionDenied = true
        } else {
          throw e
        }
      }
    },
    restart () {
      this.stop()
      this.active && this.start()
    },
    stop () {
      this.isActive = false
      this.controlers && this.controlers.stop()
      this.controlers = null
    },
  },
  watch: {
    active: 'restart',
    deviceId (v) {
      localStorage.setItem(DEVICE_ID_KEY, v)
    },
    lightOn (bool) {
      this.controlers && this.controlers.switchTorch && this.controlers.switchTorch(bool)
      localStorage.setItem('scan-code-light-on', Number(bool))
    },
  },
}
