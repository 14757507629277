import Vue from 'vue'
import { VIcon, VTab, VTabs } from 'vuetify/lib/components'
import { completeArray, parseArray } from '@/utils/smarteh/filter'
import VSelectList from 'vuetify/lib/components/VSelect/VSelectList'

export default function (genListWithSlot) {
  return Vue.extend({
    data: () => ({
      lazyNot: false,
    }),
    props: {
      canNotFilter: {
        type: Boolean,
      },
    },
    computed: {
      internalValue: {
        get () {
          if (this.multiple) {
            return parseArray(this.lazyValue || []).array
          } else {
            return this.lazyValue
          }
        },
        set (val) {
          if (this.multiple && val.length) {
            this.lazyValue = completeArray(val, this.lazyNot)
          } else {
            this.lazyValue = val
          }
          this.$emit('input', this.lazyValue)
        },
      },
    },
    methods: {
      genList () {
        if (this.$slots['no-data'] || this.$slots['prepend-item'] ||
          this.$slots['append-item'] || this.canNotFilter && this.multiple) {
          return this.genListWithSlot()
        } else {
          return this.staticList
        }
      },
      genNotFilter () {
        return this.$createElement(
          VTabs,
          {
            props: {
              fixedTabs: true,
              value: this.lazyNot ? 1 : 0,
            },
            on: {
              change: (val) => {
                this.lazyNot = val === 1
                if (this.internalValue.length) {
                  this.setValue(this.internalValue, true)
                }
              },
            },
            class: 'mt-n2 v-tabs--dense',
          },
          [
            this.$createElement(
              VTab,
              [
                this.$createElement(VIcon, {
                  props: {
                    xSmall: true,
                  },
                }, ['$equals']),
              ],
            ),
            this.$createElement(
              VTab,
              [
                this.$createElement(VIcon, {
                  props: {
                    xSmall: true,
                  },
                }, ['$not-equal']),
              ],
            ),
          ],
        )
      },
      genPrependInnerSlot () {
        const slot = []

        if (this.$slots['prepend-inner']) {
          slot.push(this.$slots['prepend-inner'])
        } else if (this.prependInnerIcon) {
          slot.push(this.genIcon('prependInner'))
        } else if (this.canNotFilter && this.internalValue.length) {
          slot.push(this.$createElement(VIcon, {
            props: {
              small: true,
              color: this.validationState,
            },
            class: 'mt-1 ml-n1',
          }, this.lazyNot ? '$not-equal' : '$equals'))
        }

        return this.genSlot('prepend', 'inner', slot)
      },

      genListWithSlot () {
        const slots = ['prepend-item', 'no-data', 'append-item'].filter(
          slotName => this.$slots[slotName]).
          map(slotName => this.$createElement('template', {
              slot: slotName,
            }, this.$slots[slotName]),
          )
        if (!this.$slots['prepend-item'] && this.canNotFilter &&
          this.multiple) {
          slots.push(this.$createElement('template', {
            slot: 'prepend-item',
          }, [this.genNotFilter()]))
        }
        const list = this.$createElement(VSelectList, {
          ...this.listData,
        }, slots)

        return list
      },

      setValue (value, dontComparate) {
        if (!this.valueComparator(value, this.internalValue) || dontComparate) {
          this.internalValue = value
          this.$emit('change', this.lazyValue)
        }
      },
    },
    watch: {
      internalValue () {
        if (this.canNotFilter) {
          this.$nextTick(() => {
            this.setPrependWidth()
          })
        }
      },
    },
  })
}
