export const defaultData = () => {
  return {
    list: [],
    filter: {},

    loading: {
      orders: false,
    },
    error: {
      orders: false,
    },

    show_filter: null,
  }
};

export default () => defaultData()
