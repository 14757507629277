
import { VBtn, VIcon } from 'vuetify/lib/components'
import VTooltip from '@/components/v/tooltip'
import { mergeData, mergeProps } from '@/utils/mergeData'

export default {
  name: 's-btn',
  functional: true,
  props: {
    ...VTooltip.options.props,
    ...VIcon.options.props,
    ...VBtn.options.props,
    activeClass: mergeProps(VBtn.options.props.activeClass, {
      default: ''
    }),
    iconProps: Object,
    tooltip: String,
    icon: [String, Boolean],
    btnRef: String,
  },
  render (createElement, context) {
    const children = typeof context.props.icon === 'string'
      ? [createElement(
        VIcon,
        mergeData({
          props: context.props
        }, {
          props: context.props.iconProps || {},
        }),
        context.props.icon
      )]
      : context.children
    const btnData = mergeData(
      context.data,
      {
        props: {
          ...context.props,
          icon: !!context.props.icon
        },
      }
    )
    if (!context.props.tooltip && !context.scopedSlots.tooltip) {
      return createElement(
        VBtn,
        {
          ...btnData,
          ref: context.data.ref || context.props.btnRef
        },
        children
      )
    }
    return createElement(
      VTooltip,
      {
        props: context.props,
        ref: context.data.ref,
        scopedSlots: {
          activator: (props) => createElement(
            VBtn,
            mergeData(
              props,
              btnData,
              {
                ref: context.data.ref || context.props.btnRef
              }
            ),
            children,
          )
        },
      },
      context.props.tooltip || context.scopedSlots.tooltip()
    )
  }
}
