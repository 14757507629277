export default {

  RESET({commit}) {
    console.log('app/RESET')

    commit('profile/SET_DATA', null, {root: true});
    // const modules = ['profile'];
    //
    // for (module of modules)
    //   commit(`${module}/RESET`, null, {root: true})
  }
}
