export const urgencies = [
  {
    title: 'Аварійна',
    description: 'Несправність загрожує життю',
    color: 'red',
    value: 'EMERGENCY',
  },
  {
    title: 'Термінова',
    description: 'Несправність призведе до великих фінансових втрат',
    color: 'orange',
    value: 'URGENT',
  },
  {
    title: 'Планова',
    color: 'green',
    value: 'NORMAL',
  },
]

export const urgenciesByValue = urgencies.reduce(
  (obj, urgency) => ({ ...obj, [urgency.value]: urgency }), {},
)

export function getUrgencyData (urgency) {
  return urgenciesByValue[urgency] || null
}
