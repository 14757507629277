
import { factory } from 'vuetify/lib/mixins/toggleable'
import { isSupport } from '@/components/s/scanner.vue'
import { isLocalLink, isMaterialLink, isTaskLink, isWarehouseLink } from '@/utils/link'

export default {
  name: 's-scan-code',
  mixins: [
    factory('visible', 'visible'),
  ],
  data () {
    return {
      result: null,
      scannerSupport: isSupport(),
      copySupport: navigator && navigator.clipboard && true || false,
    }
  },
  computed: {
    resultIsLink () {
      return this.result && (this.result.startsWith('https://') || this.result.startsWith('http://')) || false
    },
  },
  methods: {
    open () {
      this.isActive = true
    },
    onResult (result) {
      this.$refs.scanner.stop()
      const text = result.getText()
      let match
      if (match = isMaterialLink(text)) {
        this.$router.push(`/materials/${match}`)
      } else if (match = isTaskLink(text)) {
        this.$router.push(`/tasks/${match}`)
      } else if (match = isWarehouseLink(text)) {
        this.$router.push(`/warehouses/${match}`)
      } else if (match = isLocalLink(text)) {
        this.$router.push(match)
      } else {
        this.result = text
      }
    },
    init () {
      this.$nextTick(() => {
        this.$refs.scanner.start()
      })
    },
    copyResult () {
      navigator.clipboard.writeText(this.result)
      this.$fire.analytics.logEvent('scan_copy', {
        method: 'copy',
        data: this.result,
      })
    },
  },
  watch: {
    isActive: {
      handler (v) {
        if (v) {
          this.$blockRouteLeave(this, () => {
            this.result = null
            this.isActive = false
            return true
          })
        } else {
          this.$refs.scanner && this.$refs.scanner.stop()
          this.$unBlockRouteLeave(this)
        }
      },
      immediate: true,
    },
    result (v) {
      if (!v) {
        this.$refs.scanner.restart()
      }
    },
  },
}
