import {
  filterParametersFilter as baseFilterParametersFilter,
  filterToQuery as baseFilterToQuery,
  filterFromQuery as baseFilterFromQuery,
  getDefaultFilterByMap,
} from '@/utils/smarteh/filter'

const MaterialsFilterMap = {
  search: String,
  material_id: Number,
  category_id: Number,
  archived: Boolean,
}

export const MATERIAL_PARAM_KEY_PREFIX = 'p_'
export const MATERIAL_PARAM_REGEX = /^p_(\d+)$/

export function getPropertyFilterKey (param) {
  return MATERIAL_PARAM_KEY_PREFIX + param
}

export function getPropertyIDFromFilterKey (prop) {
  prop = prop.match(MATERIAL_PARAM_REGEX)
  if (!prop) return null
  return +prop[1]
}

export function filterParametersFilter (filter) {
  const result = baseFilterParametersFilter(filter, MaterialsFilterMap)
  for (const key in filter) {
    if (!MATERIAL_PARAM_REGEX.test(key)) continue
    if (!Array.isArray(filter[key]) || !filter[key].length) continue
    result[key] = filter[key]
  }
  return result
}

export function getDefaultFilter () {
  return getDefaultFilterByMap(MaterialsFilterMap)
}

export function filterToQuery (filter) {
  const query = baseFilterToQuery(filter, MaterialsFilterMap)
  for (const key in filter) {
    if (!MATERIAL_PARAM_REGEX.test(key)) continue
    if (!Array.isArray(filter[key]) || !filter[key].length) continue
    query[key] = filter[key].join(',')
  }
  return query
}

export function filterFromQuery (query) {
  const filter = baseFilterFromQuery(query, MaterialsFilterMap)
  for (const key in filter) {
    if (!MATERIAL_PARAM_REGEX.test(key)) continue
    if (!Array.isArray(filter[key]) || !filter[key].length) continue
    query[key] = filter[key].join(',')
  }
  return filter
}
